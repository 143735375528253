import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { SnackbarProvider, closeSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import { ErrorBoundary } from '@layout/error_boundary';
import { UserProvider } from '@pages/users/UserContext';
import { routers } from '@shared/config/routers';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CountProvider } from '@widgets/nav_bar/api/CountContext';
import { UserContextProvider } from '@widgets/users_info/UserContext';
import { AlkoContextProvider } from '@widgets/vehicles_info/lib/AlkoContext';

import './index.scss';
import { StatusFilterProvider } from '@shared/ui/search_multiple_select/StatusFilterContext';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <UserProvider>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <UserContextProvider>
            <CountProvider>
              <AlkoContextProvider>
              <StatusFilterProvider>
                <SnackbarProvider
                  action={(snackbarId) => (
                    <CloseIcon className="CloseIcon" onClick={() => closeSnackbar(snackbarId)} />
                  )}
                  maxSnack={3}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
                  <RouterProvider router={routers} />
                </SnackbarProvider>
              </StatusFilterProvider>
              </AlkoContextProvider>
            </CountProvider>
          </UserContextProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </UserProvider>
  </ErrorBoundary>,
);
