/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { CircularProgress, Skeleton, Stack, Tooltip } from '@mui/material';

import type { ImageStateInStore } from '@entities/upload_img';
import { UsersApi } from '@shared/api/baseQuerys';
import type { ID } from '@shared/types/BaseQueryTypes';
import { ImageView } from '@shared/ui/image_view';

import { useUserFotoItem } from '../hooks/useUserFotoItem';
import { ErrorLoadImg } from './ErrorLoadImg';
import style from './UserFotoItem.module.scss';

type UserFotoItemProps = {
  imageItem: ImageStateInStore;
  userId: ID;
  onClickView: () => void;
  deleteImageMemo: (imageID: ID) => void;
  setImageToStoreAfterLoadingMemo: (image: ImageStateInStore) => void;
  changeAvatarMemo: (idImage: ID) => void;
};

export const UserFotoItem = ({
  imageItem,
  onClickView,
  setImageToStoreAfterLoadingMemo,
  deleteImageMemo,
  changeAvatarMemo,
  userId,
}: UserFotoItemProps) => {
  const {
    isLoadingImage,
    handleDeleteImage,
    isError,
    isDeleteImage,
    handleChangeAvatar,
    isLoadingChangeAvatar,
  } = useUserFotoItem(
    imageItem,
    setImageToStoreAfterLoadingMemo,
    deleteImageMemo,
    changeAvatarMemo,
    userId,
  );

  const [permission, setPermission] = useState<string[]>([]);
  const isDisabled = userId === 1 && !permission.includes('SYSTEM_GLOBAL_ADMIN');
  console.log(userId, isDisabled);
  

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await UsersApi.getInfo();
        setPermission(response.data?.permissions || []);
      } catch (error) {
        // console.error('Ошибка получения данных пользователя:', error);
      }
    };

    fetchUserData();
  }, []);

  const hasCreatePermission = permission.includes('PERMISSION_USER_CREATE');
  const isSavedInDataBase = imageItem?.isSavedInDataBase || false;

  const showImage = isSavedInDataBase && imageItem?.src && !isDeleteImage;
  const showLoading =
    (!isSavedInDataBase && !isLoadingImage) || isDeleteImage || isLoadingChangeAvatar;
  const showSkeleton = isLoadingImage && !imageItem?.src && !isDeleteImage;
  const showError = !imageItem?.src && !isLoadingImage && isError;
  const isAvatar = imageItem?.isAvatar || false;

  if (showSkeleton) {
    return <Skeleton className={style.skeleton} animation="wave" />;
  } else if (showError) {
    return <ErrorLoadImg />;
  }
  return (
    <>
      <figure className={`${style.imageItem} ${style.animateOnFirstRender}`}>
        <ImageView styleWrapper={style.imageWrapper} src={imageItem?.src} />
        {showImage && (
          <>
            <Stack
              alignItems={'center'}
              className={`${style.actions}`}
              direction={'row'}
              justifyContent={'space-around'}>
              {hasCreatePermission && !isDisabled && (
                <Tooltip title="Удалить фото из галереи">
                  <span onClick={!isDisabled ? handleDeleteImage : undefined}>
                    <DeleteForeverOutlinedIcon color={isDisabled ? 'disabled' : 'inherit'} />
                  </span>
                </Tooltip>
              )}
              {hasCreatePermission && !isAvatar && (
                <Tooltip title="Сменить фото профиля">
                  <span onClick={!isDisabled ? handleChangeAvatar : undefined}>
                    <AccountCircleOutlinedIcon color={isDisabled ? 'disabled' : 'inherit'} />
                  </span>
                </Tooltip>
              )}
              <Tooltip title="Посмотреть фото">
                <span onClick={onClickView}>
                  <RemoveRedEyeOutlinedIcon />
                </span>
              </Tooltip>
            </Stack>
            {isAvatar && (
              <Tooltip title="Аватар профиля">
                <span className={style.avatar}>
                  <AccountCircleOutlinedIcon />
                </span>
              </Tooltip>
            )}
          </>
        )}
        {showLoading && <CircularProgress className={style.clock} />}
      </figure>
    </>
  );
};
